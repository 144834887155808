import { Box, Button, Chip, Stack } from "@mui/material";
import {
  TripSortCriteria,
  AssetStatus,
  GetTrailerListQuery,
  NewTrailerInput,
  CustomFieldContext,
  TripAssetTypes,
  TrailerType,
} from "../../../graphql/generated";
import { TableField } from "../../common/LynksTable/LynksTable";
import SmartLynksTable, {
  QueryFn,
} from "../../common/SmartLynksTable/SmartLynksTable";
import { EnumTableField } from "../../common/LynksTable/LynksTable";
import trailerTypeLabel from "../../../utils/labels/trailerTypeLabel";
import ExcelImporter from "../../common/ExcelImporter";
import trailerSchema from "../TrailerForm/trailerSchema";
import trailersMapping from "../../../utils/mapping/trailers";
import { useTranslation } from "react-i18next";
import MaintenanceWarningsContainer from "../../maintenance/MaintenanceWarnings";

type TrailerListItem = GetTrailerListQuery["trailers"]["data"][0];

export default function TrailersList({
  query,
  onBulkCreate,
}: {
  query: QueryFn<TrailerListItem, "trailers", TripSortCriteria>;
  onBulkCreate: (data: NewTrailerInput[]) => Promise<any>;
}) {
  const { t } = useTranslation(["assets", "common"]);

  const statusField: EnumTableField<TrailerListItem, AssetStatus> = {
    value: (trailer) => trailer.status,
    label: t("assets:status", "Status"),
    valueLabel: (status) => t(`common:statusTypes.${status}`),
    type: "enum",
    colors: {
      ACTIVE: "primary",
      INACTIVE: "default",
    },
    values: Object.values(AssetStatus),
  };

  const fields: TableField<TrailerListItem, AssetStatus>[] = [
    {
      value: "serialNumber",
      label: t("assets:code", "Asset code"),
      type: "string",
    },
    {
      value: "licenseNumber",
      label: t("assets:licenseNumber", "License Number"),
      type: "string",
    },
    {
      value: (trailer) => trailer.domicile?.label,
      label: t("assets:address", "Address"),
      type: "string",
      sortBy: "domicile.label",
    },
    statusField,
    {
      value: (trailer) => trailerTypeLabel(trailer.type),
      label: t("assets:trailer.type", "Type"),
      type: "select",
      sortBy: "type",
      values: Object.values(TrailerType),
    },
    {
      value: (trailer) => (
        <Stack direction="row" spacing={1}>
          {trailer.tags?.map((tag) => (
            <Chip label={tag} />
          ))}
        </Stack>
      ),
      sortBy: "tags",
      label: t("common:tags"),
      type: "string",
    },
    {
      value: (trailer) => (
        <MaintenanceWarningsContainer
          assetType={TripAssetTypes.Trailer}
          assetId={trailer._id}
        />
      ),
      label: t("maintenance"),
      type: "string",
    },
  ];
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
      }}
    >
      <Box
        sx={{
          height: "calc(100% - 74px)",
        }}
      >
        <SmartLynksTable
          query={query}
          dataKey="trailers"
          fields={fields}
          detailsUrlPrefix="/trailers/details"
          customFieldContext={[CustomFieldContext.Trailer]}
          selectable
          showGroups
          renderHeaderActions={() => (
            <Stack direction="row" spacing={2}>
              <ExcelImporter
                mapping={trailersMapping}
                schema={trailerSchema}
                onBulkCreate={onBulkCreate}
              />
              <Button
                href="/trailers/new"
                variant="contained"
                color="secondary"
                size="large"
                id="new-trailer-button"
              >
                {t("assets:trailer.new", "New Trailer")}
              </Button>
            </Stack>
          )}
        />
      </Box>
    </div>
  );
}
