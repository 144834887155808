import { keyBy, without } from "lodash";
import { useSnackbar } from "notistack";
import React, { useEffect, useMemo, useState } from "react";
import {
  CustomFieldContext,
  ExtensionOperationContext,
  OrderByDirection,
  OrderByItem,
  useCallExtensionOperationMutation,
  useGetCustomFieldDefinitionsQuery,
  useGetExtensionsOperationsQuery,
  useGetGroupsQuery,
} from "../../../graphql/generated";
import useDialog from "../../../utils/hooks/useDialog";
import ErrorMessage from "../ErrorMessage/ErrorMessage";
import LynksTable from "../LynksTable";
import { LynksTableProps, Sort, TableField } from "../LynksTable/LynksTable";
import { useTranslation } from "react-i18next";
import LoadingOverlay from "../LoadingOverlay";
import BulkActionModal from "../BulkActionModal";
import { Stack } from "@mui/material";
import { Edit } from "@mui/icons-material";
import DropdownButtonGroup, { ActionMenu } from "../DropdownButtonGroup";
import {
  GridCallbackDetails,
  GridFilterItem,
  GridFilterModel,
  GridSortModel,
} from "@mui/x-data-grid-pro";

const DEFAULT_ROWS_COUNT = 25;

type SmartLynksTableQueryVariables<C, V = {}> = {
  search?: string;
  sort?: Sort<C> | null;
  skip?: number;
  take?: number;
  filter?: { [key: string]: any };
  orderBy?: OrderByItem[];
} & V;

type SmartLynksTableQueryOptions = {
  retry?: boolean;
  refetchOnWindowFocus?: boolean;
  refetchOnMount?: boolean;
  refetchOnReconnect?: boolean;
  refetchInterval?: number;
  refetchIntervalInBackground?: boolean;
  refetchOnLimit?: boolean;
  retryDelay?: number;
  staleTime?: number;
  cacheTime?: number;
  useErrorBoundary?: boolean;
  onError?: (error: Error) => void;
  enabled?: boolean;
  suspense?: boolean;
  keepPreviousData?: boolean;
  structuralSharing?: boolean;
};

export type QueryFn<T, K extends string, C = unknown, V = {}> = (
  variables: SmartLynksTableQueryVariables<C, V>,
  queryOptions?: SmartLynksTableQueryOptions
) => {
  data:
    | {
        [key in K]:
          | {
              data: T[];
              count: number | null;
            }
          | undefined;
      }
    | undefined;
  isLoading: boolean;
  isPending?: boolean;
  isFetching?: boolean;
  isRefetching?: boolean;
  error?: Error | null;
  refetch?: () => Promise<unknown>;
};

export type MutationFn<V = {}, R = unknown> = () => {
  mutateAsync: (variables: V) => Promise<R>;
  isLoading: boolean;
  error?: Error | null;
};

export type DeleteMutation = MutationFn<{ id: string }>;

export type SmartLynksTableProps<
  T,
  K extends string,
  E extends string | number | symbol = never,
  C extends string = never,
  V = {}
> = {
  fields?: LynksTableProps<T, E, C>["fields"];
  renderRow?: LynksTableProps<T, E, C>["renderRow"];
  renderHeader?: LynksTableProps<T, E, C>["renderHeader"];
  sortFields?: LynksTableProps<T, E, C>["sortFields"];
  actions?: LynksTableProps<T, E, C>["actions"];
  onRecordClick?: LynksTableProps<T, E, C>["onRecordClick"];
  onSelect?: LynksTableProps<T, E, C>["onSelect"];
  selectable?: boolean;
  detailsUrlPrefix?: LynksTableProps<T, E, C>["detailsUrlPrefix"];
  dataKey: K;
  query: QueryFn<T, K, C, V>;
  additionalQueryVariables?: V;
  queryOptions?: SmartLynksTableQueryOptions;
  disableSearch?: boolean;
  disablePagination?: boolean;
  deleteMutation?: DeleteMutation;
  extensionOperationContext?: ExtensionOperationContext;
  id?: LynksTableProps<T, E, C>["id"];
  customFieldContext?: CustomFieldContext[];
  showGroups?: boolean;
  // additionnalsCustomFieldsContext?: CustomFieldContext[];
  customFieldsGetter?: LynksTableProps<T, E, C>["customFieldsGetter"];
  getRecordParentId?: LynksTableProps<T, E, C>["getRecordParentId"];
  onFilteredRecordsChange?: LynksTableProps<T, E, C>["onFilteredRecordsChange"];
  bulkActionMenus?: Array<ActionMenu>;
  renderHeaderActions?: () => React.ReactNode;
};

export default function SmartLynksTable<
  T extends {
    _id: string;
    groupIds?: string[] | null;
  },
  K extends string,
  E extends string | number | symbol = never,
  C extends string = never,
  V = {}
>({
  fields,
  renderRow,
  renderHeader,
  sortFields,
  actions,
  query,
  additionalQueryVariables,
  queryOptions,
  dataKey,
  detailsUrlPrefix,
  onRecordClick,
  onSelect,
  selectable,
  disableSearch,
  disablePagination,
  deleteMutation,
  extensionOperationContext,
  id,
  customFieldContext = [],
  showGroups,
  customFieldsGetter,
  getRecordParentId,
  onFilteredRecordsChange,
  bulkActionMenus,
  renderHeaderActions,
}: SmartLynksTableProps<T, K, E, C, V>) {
  const { t } = useTranslation("common");
  const [take, setTake] = useState(DEFAULT_ROWS_COUNT);
  const [skip, setSkip] = useState(0);
  const [sort, setSort] = useState<Sort<C> | null>(null);
  const [selected, setSelected] = useState<T[]>([]);
  const [loadingRecordIds, setLoadingRecordIds] = useState<string[]>([]);
  const [isBulkActionModalOpen, setIsBulkActionModalOpen] = useState(false);
  const [filter, setFilter] = useState<{ [key: string]: any }>({});
  const [search, setSearch] = useState<string | null>(null);
  const [orderBy, setOrderBy] = useState<OrderByItem[]>([]);

  const { showDialog, hideDialog } = useDialog();
  const { enqueueSnackbar } = useSnackbar();

  const groupsQuery =
    process.env.NODE_ENV !== "test"
      ? // eslint-disable-next-line react-hooks/rules-of-hooks
        useGetGroupsQuery()
      : null;
  const groupsData = useMemo(
    () => groupsQuery?.data?.groups.data || [],
    [groupsQuery?.data]
  );
  const groupMap = useMemo(() => keyBy(groupsData, (g) => g._id), [groupsData]);

  const augmentedFields = useMemo(() => {
    const groupsField: TableField<T> = {
      value: (row: T) =>
        (row?.groupIds || [])
          .map((id) => groupMap[id])
          .filter(Boolean)
          .map((g) => g.name),
      label: t("groups"),
      type: "select",
      values: groupsData.map((g) => g.name),
    };
    return showGroups ? fields?.concat(groupsField) : fields;
  }, [fields, showGroups, groupMap, groupsData, t]);

  const queryResult = query(
    //@ts-ignore additionalQueryVariables will always be compatible
    // with query variables (type V) but somehow ts does not see it that way
    {
      sort,
      take,
      skip,
      filter,
      search,
      orderBy,
      ...(additionalQueryVariables || {}),
    },
    {
      retry: false,
      ...(queryOptions || {}),
      keepPreviousData: true,
    }
  );

  const deleteMutationHandle = deleteMutation ? deleteMutation() : null;

  // const previousSelected = usePrevious(selected);
  // useEffect(() => {
  //   // avoid resetting page when user select a load
  //   if (!selected.length && previousSelected?.length !== 1) {
  //     setSkip(0);
  //   }
  //   // we do not want to reset the page when selected loads change
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [sort, additionalQueryVariables]);

  const data = useMemo(() => {
    return queryResult.data ? queryResult.data[dataKey]?.data || [] : [];
  }, [queryResult.data, dataKey]);

  const count = useMemo(() => {
    return queryResult.data ? queryResult.data[dataKey]?.count || null : null;
  }, [queryResult.data, dataKey]);

  useEffect(() => {
    setSelected([]);
  }, [data]);

  const extensionsOperationsQuery =
    process.env.NODE_ENV !== "test" && extensionOperationContext
      ? // eslint-disable-next-line react-hooks/rules-of-hooks
        useGetExtensionsOperationsQuery({
          context: extensionOperationContext,
        })
      : null;
  const extensionOperations =
    extensionsOperationsQuery?.data?.extensionsOperations || [];

  const callExtensionOperationMutation =
    process.env.NODE_ENV !== "test"
      ? // eslint-disable-next-line react-hooks/rules-of-hooks
        useCallExtensionOperationMutation()
      : null;

  const customFieldDefinitionsQuery =
    customFieldContext.length && process.env.NODE_ENV !== "test"
      ? // eslint-disable-next-line react-hooks/rules-of-hooks
        useGetCustomFieldDefinitionsQuery()
      : null;

  const customFieldDefinitions =
    customFieldDefinitionsQuery?.data?.cutomFieldDefinitions.data.filter(
      (customFieldDefinition) =>
        customFieldContext.length &&
        customFieldDefinition.context.some((ctx) =>
          customFieldContext.includes(ctx)
        )
    );

  if (customFieldDefinitionsQuery?.isLoading || groupsQuery?.isLoading) {
    return <LoadingOverlay loading />;
  }

  return (
    <>
      <ErrorMessage error={queryResult.error} />
      {isBulkActionModalOpen && (
        <BulkActionModal
          entityIds={selected.map((entity) => entity._id)}
          onSubmitComplete={() => queryResult?.refetch?.()}
          dataKey={dataKey}
          open={isBulkActionModalOpen}
          onClose={() => setIsBulkActionModalOpen(false)}
        />
      )}
      <Stack
        direction="row"
        justifyContent={
          (selectable || onSelect) && selected.length > 0
            ? "space-between"
            : "flex-end"
        }
        spacing={2}
        mb={2}
      >
        {(selectable || onSelect) && selected.length > 0 && (
          <DropdownButtonGroup
            color="secondary"
            variant="outlined"
            size="large"
            menus={[
              {
                id: "edit",
                icon: <Edit />,
                label: t("list.edit"),
                onClick: () => {
                  if (selected.length === 0) {
                    showDialog({
                      title: t(
                        "common:list.selectItemFirst",
                        "Select an item first"
                      ),
                      description: t(
                        "common:list.selectBulkError",
                        "Please select at least one item to use bulk actions"
                      ),
                      type: "primary",
                    });
                    return;
                  }
                  setIsBulkActionModalOpen(true);
                },
              },
              ...(bulkActionMenus || []),
            ]}
            id="bulk-action-buttons"
          />
        )}
        <Stack direction="row" spacing={2}>
          {renderHeaderActions ? renderHeaderActions() : null}
        </Stack>
      </Stack>
      {/* @ts-expect-error */}
      <LynksTable
        data={data}
        fields={augmentedFields}
        renderRow={renderRow}
        renderHeader={renderHeader}
        sortFields={sortFields}
        loadingRecordIds={loadingRecordIds}
        actions={(actions || []).concat(
          extensionOperations.map((extensionOperation) => ({
            icon: (
              <img
                src={extensionOperation.icon}
                alt={extensionOperation.description}
                style={{
                  width: 20,
                  height: 20,
                }}
              />
            ),
            tooltip: extensionOperation.name,
            onClick: async (item) => {
              try {
                if (!callExtensionOperationMutation) {
                  return;
                }
                setLoadingRecordIds(loadingRecordIds.concat(item._id));
                const response =
                  await callExtensionOperationMutation.mutateAsync({
                    extensionId: extensionOperation.extensionId,
                    operationKey: extensionOperation.key,
                    recordId: item._id,
                  });
                if (response.callExtensionOperation.message) {
                  enqueueSnackbar(response.callExtensionOperation.message);
                }
                if (response.callExtensionOperation.redirect) {
                  if (response.callExtensionOperation.openNewWindow) {
                    window.open(
                      response.callExtensionOperation.redirect,
                      `${item._id}::${extensionOperation.extensionId}::${extensionOperation.key}`,
                      `scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,width=992,height=680,left=100,top=100`
                    );
                  } else {
                    window.location.href =
                      response.callExtensionOperation.redirect;
                  }
                }
              } catch (error) {
                showDialog({
                  title: t("error.title", "Error"),
                  description: (error as Error).message,
                  type: "error",
                });
              } finally {
                setLoadingRecordIds(without(loadingRecordIds, item._id));
              }
            },
          }))
        )}
        loading={queryResult.isLoading}
        detailsUrlPrefix={detailsUrlPrefix}
        disableSearch={disableSearch}
        onSortChange={setSort}
        count={disablePagination ? null : count}
        page={skip / take + 1}
        perPage={disablePagination ? undefined : take}
        onRecordClick={onRecordClick}
        onSelect={(selected) => {
          setSelected(selected);
          if (onSelect) {
            onSelect(selected);
          }
        }}
        onDelete={
          deleteMutationHandle
            ? async (id) => {
                showDialog({
                  title: t("error.dangerZone", "Danger zone"),
                  description: t(
                    "error.deleteConfirmation",
                    "Do you really want to delete this? This action cannot be undone."
                  ),
                  type: "error",
                  actions: [
                    {
                      type: "primary",
                      title: t("error.noCancel", "No, Cancel"),
                    },
                    {
                      type: "error",
                      title: t("error.yesDelete", "Yes, Delete"),
                      onClick: async () => {
                        try {
                          setLoadingRecordIds(loadingRecordIds.concat(id));

                          await deleteMutationHandle.mutateAsync({
                            id,
                          });
                          hideDialog();
                          if (queryResult.refetch) {
                            queryResult.refetch();
                          }
                        } catch (error) {
                          console.error(error);
                          showDialog({
                            title: t("error.title", "Error"),
                            description:
                              t(
                                "error.deleteError",
                                "An error occurred while deleting."
                              ) +
                              (deleteMutationHandle.error?.message ||
                                t("error.unknownError", "Unknown error")),
                          });
                        } finally {
                          setLoadingRecordIds(without(loadingRecordIds, id));
                        }
                      },
                    },
                  ],
                });
              }
            : undefined
        }
        id={id || dataKey}
        customFieldDefinitions={customFieldDefinitions}
        customFieldsGetter={customFieldsGetter}
        getRecordParentId={getRecordParentId}
        onFilteredRecordsChange={onFilteredRecordsChange}
        filterMode="server"
        onFilterModelChange={(model, details) => {
          setSearch(model.quickFilterValues?.[0] || null);
          setFilter(gridFilterModelToApiFilter(model, details));
        }}
        paginationMode="server"
        onPaginationModelChange={(pagination) => {
          setTake(pagination.pageSize);
          setSkip(pagination.page * pagination.pageSize);
        }}
        sortingMode="server"
        onSortModelChange={(model, details) => {
          setOrderBy(gridSortModelToApiOrderBy(model, details));
        }}
        rowCount={count || 0}
      />
    </>
  );
}

type ApiFilter = {
  [key: string]: any;
};

const operatorRequiresValue = (operator: string): boolean => {
  switch (operator) {
    case "isEmpty":
    case "isNotEmpty":
      return false;
    default:
      return true;
  }
};

const gridFilterModelToApiFilter = (
  model: GridFilterModel,
  details: GridCallbackDetails<"filter">
): ApiFilter => {
  const itemsWithoutEmptyValues = model.items.filter(
    (item) =>
      !operatorRequiresValue(item.operator) ||
      (item.value !== "" && item.value !== null && item.value !== undefined)
  );
  if (!itemsWithoutEmptyValues.length) {
    return {};
  }
  return {
    [model.logicOperator === "and" ? "$and" : "$or"]:
      itemsWithoutEmptyValues.map((item) => {
        const column = details.api.getColumn(item.field);
        const field = String(column.cellClassName || column.field);
        if (field.startsWith("customFields.")) {
          return {
            customFields: {
              $elemMatch: {
                key: field.replace("customFields.", ""),
                value: {
                  [gridFilterOperatorToApiOperator(item.operator)]:
                    gridFilterValueToApiValue(item),
                },
              },
            },
          };
        }
        return {
          [String(column.cellClassName || column.field)]: {
            [gridFilterOperatorToApiOperator(item.operator)]:
              gridFilterValueToApiValue(item),
          },
        };
      }),
  };
};

const gridFilterOperatorToApiOperator = (operator: string): string => {
  switch (operator) {
    case "equals":
    case "is":
    case "=":
      return "$eq";
    case "notEquals":
    case "not":
    case "!=":
      return "$ne";
    case "contains":
      return "$regex";
    case "notContains":
      return "$not";
    case "startsWith":
      return "$regex";
    case "endsWith":
      return "$regex";
    case ">":
    case "after":
      return "$gt";
    case "<":
    case "before":
      return "$lt";
    case ">=":
    case "onOrAfter":
      return "$gte";
    case "<=":
    case "onOrBefore":
      return "$lte";
    case "isEmpty":
      return "$eq";
    case "isNotEmpty":
      return "$ne";
    case "isAnyOf":
      return "$in";
    default:
      return "$eq";
  }
};

const gridFilterValueToApiValue = (item: GridFilterItem): any => {
  if (item.operator === "contains") {
    return `.*${item.value}.*`;
  }
  if (item.operator === "isEmpty" || item.operator === "isNotEmpty") {
    return null;
  }

  if (["=", ">", "<", ">=", "<="].includes(item.operator)) {
    return Number(item.value);
  }

  if (item.value === "true" || item.value === "false") {
    return item.value === "true";
  }

  return item.value;
};

const gridSortModelToApiOrderBy = (
  model: GridSortModel,
  details: GridCallbackDetails<any>
): OrderByItem[] => {
  return model.map((sortItem) => {
    const column = details.api.getColumn(sortItem.field);
    return {
      field: String(column.cellClassName || column.field),
      direction:
        sortItem.sort === "desc" ? OrderByDirection.Desc : OrderByDirection.Asc,
    };
  });
};
