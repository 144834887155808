import { Box, Button, Chip, Stack } from "@mui/material";
import {
  TripSortCriteria,
  Status,
  GetBusinessEntityListQuery,
  BusinessEntityType,
  NewBusinessEntityInputWithType,
  CustomFieldContext,
} from "../../../graphql/generated";
import formatPhoneNumber from "../../../utils/labels/formatPhoneNumber";
import { TableField } from "../../common/LynksTable/LynksTable";
import SmartLynksTable, {
  QueryFn,
} from "../../common/SmartLynksTable/SmartLynksTable";
import ExcelImporter from "../../common/ExcelImporter";
import customersMapping from "../../../utils/mapping/customers";
import businessEntitySchema from "../BusinessEntityForm/businessEntitySchema";
import { useTranslation } from "react-i18next";
import { useMemo } from "react";

type CustomerListItem =
  GetBusinessEntityListQuery["businessEntities"]["data"][0];

export default function CustomersList({
  query,
  onBulkCreate,
}: {
  query: QueryFn<CustomerListItem, "businessEntities", TripSortCriteria>;
  onBulkCreate: (data: NewBusinessEntityInputWithType[]) => Promise<any>;
}) {
  const { t } = useTranslation(["business", "common"]);

  const fields: TableField<CustomerListItem, Status>[] = useMemo(
    () => [
      {
        value: (businessEntity) => {
          const allTypes = [
            businessEntity.type,
            ...(businessEntity.additionalTypes || []),
          ];
          if (allTypes.includes(BusinessEntityType.Broker)) {
            return `${businessEntity.code} ${
              businessEntity.mcNumber ? `(${businessEntity.mcNumber})` : ""
            }`;
          }
          return businessEntity.code;
        },
        label: t("business:code", "Code"),
        type: "string",
        sortBy: "code",
      },
      {
        value: "name",
        label: t("business:name", "Name"),
        type: "string",
      },
      {
        value: (customer) => customer.address?.label,
        label: t("business:address", "Address"),
        type: "string",
        sortBy: "address.label",
      },
      {
        value: (customer) => formatPhoneNumber(customer.contact.phoneNumber),
        subtitle: (customer) => customer.contact.email,
        label: t("business:contact", "Contact"),
        type: "string",
        sortBy: "contact.phoneNumber",
      },
      {
        value: (customer) => (
          <Stack direction="row" spacing={1}>
            {customer.tags?.map((tag) => (
              <Chip label={tag} />
            ))}
          </Stack>
        ),
        sortBy: "tags",
        label: t("common:tags"),
        type: "string",
      },
    ],
    [t]
  );
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
      }}
    >
      <Box
        sx={{
          height: "calc(100% - 74px)",
        }}
      >
        <SmartLynksTable
          query={query}
          dataKey="businessEntities"
          additionalQueryVariables={{
            businessEntityTypes: [
              BusinessEntityType.Customer,
              BusinessEntityType.Broker,
            ],
          }}
          fields={fields}
          detailsUrlPrefix="/customers/details"
          customFieldContext={[CustomFieldContext.Customer]}
          id="customers-list"
          selectable
          showGroups
          renderHeaderActions={() => (
            <Stack direction="row" spacing={2}>
              <ExcelImporter
                mapping={customersMapping}
                schema={businessEntitySchema}
                onBulkCreate={onBulkCreate}
              />
              <Button
                href="/customers/new"
                variant="contained"
                color="secondary"
                size="large"
                id="new-customer-button"
                aria-label="New Customer"
              >
                {t("business:customer.new", "New Customer")}
              </Button>
            </Stack>
          )}
        />
      </Box>
    </div>
  );
}
